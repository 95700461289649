//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { scroll } from 'quasar';
const { getScrollTarget, setScrollPosition } = scroll;
import feather from 'feather-icons';
// import I18nFlags from '../../../shared/i18n/i18n-flags.vue';
import i18n from '@/vueI18n';

export default {
  name: 'sama-el-baraka-header',
  components: {
    // [I18nFlags.name]: I18nFlags,
  },
  data: () => ({
    drawer: false,
    itemsScroll: [
      // { title: 'samaElBaraka.header.home', id: 'home' },
      { title: 'samaElBaraka.header.about', id: 'about' },
      {
        title: 'samaElBaraka.header.product',
        id: 'product',
      },
      {
        title: 'samaElBaraka.header.branches',
        id: 'branches',
      },
      {
        title: 'samaElBaraka.header.contact',
        id: 'contact',
      },
    ],
    icon: 'menu',
    closeIcon: 'x',
  }),

  computed: {
    language() {
      return i18n.locale;
    },
  },

  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },

    navigate(link) {
      const lang = localStorage.getItem('language');
      this.$router.push(`/${lang}${link}`);
    },
    navigateHome(){
      console.log(this.$router.currentRoute);
      if(this.$router.currentRoute.name!='sama-el-baraka') {
        this.$router.push(
          `/${this.language}/sama-el-baraka`,
        );
      }
    },

    async handleScroll(eleId) {
      if (
        this.$router.currentRoute.path.includes('falcon')
      ) {
        await this.$router.push(
          `/${this.language}/sama-el-baraka`,
        );
        this.handleScroll(eleId)
      } else {
        var offset;
        const ele = document.getElementById(eleId);
        var target = getScrollTarget(ele);
        var duration = 500;
        if (eleId == 'home') {
          offset = 0;
        } else {
          offset = ele.offsetTop;
        }
        setScrollPosition(target, offset, duration);
      }
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Icon } from '@iconify/vue2';
import Branches from '@/locales/en.json';
import BranchesAr from '@/locales/ar.json';
/* eslint-disable */
import 'leaflet/dist/leaflet.css';
import i18n from '@/vueI18n';

import L from 'leaflet';

export default {
  components: {
    Icon,
  },
  data() {
    return {
      isActive: 0,
      sideMenuIsOpen: false,
      zoom: 15,
      marker: null,
      customIcon: L.icon({
        iconUrl: '/location.png',
        iconSize: [45, 45],
        iconAnchor: [26, 42],
      }),
      locations: [
        {
          phoneNumber: 'tel:+20127 324 1111',
          name: {
            En: 'Agamy Branch',
            Ar: 'فرع العجمي',
          },
          latlng: ['31.1059043', '29.7625429'],
        },
        {
          phoneNumber: 'tel:+20127 323 5555',
          name: {
            En: 'Miami Branch',
            Ar: 'فرع ميامي',
          },
          latlng: ['31.2653372', '29.9966241'],
        },
        {
          phoneNumber: 'tel:+20127 323 5555',
          name: {
            En: 'Janklies Branch',
            Ar: 'فرع جناكليس',
          },
          latlng: [
            '31.241064597487043',
            '29.96825054231255',
          ],
        },
        {
          phoneNumber: 'tel:+20128 602 5300',
          name: {
            En: 'Al Amiriyah Branch',
            Ar: 'فرع العامرية',
          },
          latlng: ['31.0163311', '29.7834817'],
        },
        {
          phoneNumber: 'tel:+20122 001 2888',
          name: {
            En: 'Kafr El Dawar Branch',
            Ar: 'فرع كفر الدوار',
          },
          latlng: ['31.1333095', '30.1288445'],
        },
        {
          phoneNumber: 'tel:+20122 303 9999',
          name: {
            En: 'Damanhour Branch',
            Ar: 'فرع دمنهور',
          },
          latlng: ['30.6869374', '29.6807538'],
        },
        {
          phoneNumber: 'tel:+20128 602 5300',
          name: {
            En: 'Shubra Branch',
            Ar: 'فرع شبرا',
          },
          latlng: ['30.1013676', '31.2427869'],
        },
        {
          phoneNumber: 'tel:+20128 602 5300',
          name: {
            En: 'Faisal Branch',
            Ar: 'فرع فيصل',
          },
          latlng: ['30.0026073', '31.1719119'],
        },
        {
          phoneNumber: 'tel:+20105 066 0577',
          name: {
            En: 'Faiyum Branch',
            Ar: 'فرع الفيوم',
          },
          latlng: ['29.313267', '30.851240'],
        },
      ],
    };
  },
  methods: {
    toggleMenu() {
      this.sideMenuIsOpen = !this.sideMenuIsOpen;
    },

    navigate(latlng, index) {
      this.map.flyTo(latlng, 18, {
        animate: true,
        duration: 1.5,
      });
      this.marker.setLatLng(latlng);

      this.isActive = index;
    },
  },
  computed: {
    language() {
      return i18n.locale;
    },
    items() {
      if (this.language == 'en')
        return Branches.samaElBaraka.branches;
      return BranchesAr.samaElBaraka.branches;
    },
  },
  mounted() {
    this.map = L.map('map').setView(
      ['31.1059043', '29.7625429'],
      18,
    );
    L.tileLayer(
      'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
      {
        maxZoom: 25,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      },
    ).addTo(this.map);
    this.marker = L.marker([31.1059043, 29.7625429], {
      icon: this.customIcon,
    }).addTo(this.map);

    // this.map.on("locationerror", onLocationError);
    // this.map.on("click", (e) => {
    //   reverseGeocode([e.latlng.lat, e.latlng.lng]);
    // });
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate';
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm';
import VuePhoneNumberInput from 'vue-phone-number-input';
import MapContainer from '@/modules/home/components/map-container.vue';
import { mapActions, mapGetters } from 'vuex';
import i18n from '@/vueI18n';

export default {
  name: 'sama-el-baraka-contact',
  components: {
    [MapContainer.name]: MapContainer,
    ValidationObserver,
    ValidationProvider,
    VuePhoneNumberInput,
  },
  data() {
    return {
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: '',
        service: '',
        branch: '',
      },
      servicesEn: [
        'Customer Service Section',
        'Maintenance Department',
        'Sales Department',
        'Complaints and Proposals',
      ],
      branchesEn: [
        'Miami',
        'Roushdy',
        'Agamy',
        'Al Amiriya',
        'Kafr El Dawar',
        'Damanhour',
        'Shoubra',
        'Faisal',
      ],
      servicesAr: [
        'قسم خدمة العملاء',
        'قسم الصيانة',
        'قسم المبيعات',
        'الشكاوي والمقترحات',
      ],
      branchesAr: [
        'ميامي',
        'رشدي',
        'العجمي',
        'العامرية',
        'كفر الدوار',
        'دمنهور',
        'شبرا',
        'فيصل',
      ],
      TRansObject: {
        countrySelectorLabel: 'Country Code',
        countrySelectorError: 'Choose a country',
        phoneNumberLabel: 'Phone Number',
        example: 'Example :',
      },
      TRansObjectAr: {
        countrySelectorLabel: 'كود البلد',
        countrySelectorError: 'إختر بلد',
        phoneNumberLabel: 'رقم الهاتف',
        example: 'مثال :',
      },
    };
  },
  methods: {
    ...mapActions({
      doSubmitContactForm:
        'partner/doSubmitContactForm_SamaElBaraka',
    }),

    validateForm() {
      if (
        this.formData.firstName == '' ||
        this.formData.lastName == '' ||
        this.formData.email == '' ||
        this.formData.phoneNumber == '' ||
        this.formData.message == '' ||
        this.formData.service == '' ||
        this.formData.branch == ''
      ) {
        return false;
      }
      return true;
    },

    onSubmit() {
      console.log(this.formData);
      if (this.validateForm()) {
        this.doSubmitContactForm({
          firstName: this.formData.firstName,
          lastName: this.formData.lastName,
          email: this.formData.email,
          phoneNumber: this.formData.phone.formattedNumber,
          message: this.formData.message,
          service: this.formData.service,
          branch: this.formData.branch,
          language: this.language,
        });
        this.formData.firstName = '';
        this.formData.lastName = '';
        this.formData.email = '';
        this.formData.phoneNumber = '';
        this.formData.message = '';
        this.formData.service = '';
        this.formData.branch = '';
        this.showNotif('success');
      }
      else{
        this.showNotif('error')
      }
    },
    showNotif(value) {
      if (value == 'success')
        this.$notify({
          group: 'notification',
          title: this.i18n('contact.form.success'),
          text: this.i18n(
            'contact.form.createdSuccessfully',
          ),
          type: 'success',
          position: 'top right',
        });
      else {
        this.$notify({
          group: 'notification',
          title: this.i18n('contact.form.error'),
          text: this.i18n('contact.form.errorDescription'),
          type: 'error',
          position: 'top right',
        });
      }
    },
    getInput(payload) {
      this.formData.phone = payload;
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
  computed: {
    ...mapGetters({
      loading: 'partner/loading',
    }),
    language() {
      return i18n.locale;
    },
    services() {
      if (this.language == 'ar') {
        return this.servicesAr;
      }
      return this.servicesEn;
    },
    branches() {
      if (this.language == 'ar') {
        return this.branchesAr;
      }
      return this.branchesEn;
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'sama-el-baraka-products',
  data() {
    return {
      portfolioContent: [
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/afigr-12814.appspot.com/o/sama-el-baraka%2Ffive-stages.webp?alt=media&token=3bb57a96-dc5d-46b8-9b45-7ed3627ebe20',
          title: 'samaElBaraka.products.fiveStages',
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/afigr-12814.appspot.com/o/sama-el-baraka%2Fseven-stages.webp?alt=media&token=36a94131-0a17-46ea-b243-8b9d3ad1e839',
          title: 'samaElBaraka.products.sevenStages',
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/afigr-12814.appspot.com/o/sama-el-baraka%2Ffalcon-ad.webp?alt=media&token=c25e7756-2000-4025-8a7d-823274e66f20',
          title: 'samaElBaraka.products.falconWaterPro6',
          url: '/sama-el-baraka/falcon-water-pro-6',
        },
      ],
    };
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    navigate(item) {
      if (item.url) {
        console.log(item);
        this.$router.go(item.url);
      }
    },
  },
};

//
//
//
//

import 'leaflet/dist/leaflet.css';
// import L from 'leaflet';

export default {
  name: 'MapContainer',
  props: ['lat', 'lng', 'marker', 'productPage', 'divId'],
  data() {
    return {
      map: null,
    };
  },
  // mounted() {
  //   this.map = L.map(this.divId, {
  //     scrollWheelZoom: false,
  //   }).setView([this.lat, this.lng], 9);
  //   const myIcon = L.icon({
  //     iconUrl:
  //       'https://firebasestorage.googleapis.com/v0/b/afigr-12814.appspot.com/o/homepage%2Fmap-marker.png?alt=media&token=359f2d7c-e0b2-4bac-9f3a-0a6fcbc656cb',
  //     // ...
  //   });

  //   L.tileLayer(
  //     'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
  //   ).addTo(this.map);

  //   this.marker.forEach((element) => {
  //     L.marker([element.lat, element.lng], { icon: myIcon })
  //       .addTo(this.map)
  //       .bindPopup(element.name)
  //       .openPopup();
  //   });
  //   // L.marker([this.lat, this.lng], {icon: myIcon})
  //   //   .addTo(this.map)
  //   //   .bindPopup(this.marker)
  //   //   .openPopup();
  // },
  beforeDestroy() {
    if (this.map) {
      this.map.remove();
    }
  },
};
